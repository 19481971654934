<template>
    <div class="module-titles">
      <div class="module-title-descs">
        {{ title }}
      </div>
      <div class="rights" v-show="title == '农技专家'">
        <el-select v-model="value" placeholder="请选择" @change="handchange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </template>
  
  <script>
  import { getTypeInfo } from "@api/njfw/njfw.js";
  
  export default {
    props: {
      title: {
        type: String,
        require: true,
      },
    },
    data() {
      return {
        options: [],
        value: "",
      };
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        getTypeInfo({})
          .then((res) => {
            res.data.forEach((item) => {
              this.options.push({
                value: item.skilledType,
                label: item.skilledType,
              });
            });
        
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handchange(val){
      this.$emit('changed',val)
      }
    },
  };
  </script>
  
    <style  lang="scss" >
  @function companyW($num) {
    @return ($num * 100)/1920;
  }
  @function companyH($num) {
    @return ($num * 100)/1080;
  }
  @font-face {
    font-family: Source Han Sans CN;
    src: url(../../../assets/font/np4SotSdlbui.woff);
  }
  * {
    font-family: Source Han Sans CN;
    color: #f0f6f5;
    font-size: companyW(20vw);
    box-sizing: border-box;
  }
  .module-titles {
    position: absolute;
    top: companyH(-38vh);
    left: companyW(-19vw);
    width: companyW(546vw);
    height: companyH(109vh);
    background-image: url(../../../assets/njfwimgs/njfw.png);
    background-repeat: no-repeat;
    background-size: cover;
    .module-title-descs {
      position: absolute;
      left: companyW(53vw);
      bottom: companyH(45vh);
      width: companyW(186vw);
      height: companyH(18vh);
      font-size: companyW(20vw);
      font-family: Source Han Sans CN;
      font-weight: 700;
      font-style: italic;
      color: #ffffff;
      line-height: companyH(15vh);
      text-shadow: 0px 0px 9px rgba(32, 22, 0);
    }
  }
  .rights {
    position: absolute;
    left: companyW(377vw);
    bottom: companyH(41vh);
    z-index: 999;
  }
  
  // 2.修改背景颜色、字体颜色、边框、宽高
  .el-input__inner {
    width: companyW(125vw);
    height: companyH(30vh);
    border: 1px solid #a3c6ce;
    background: rgba(16, 36, 52) !important;
    border-radius: 0;
    text-align: center;
    color: #fff !important;
  }
  .el-input__icon {
    line-height: companyH(28vh);
  }
  .el-select-dropdown__item{
    span{
      color: #606266 !important;
    }
  }
  </style>
  
  