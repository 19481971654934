<template>
    <div class="dry-power">
      <moduleTitle title="当年研学统计"></moduleTitle>
      <div class="dry-power-content"></div>
      <div class="nongji">
        <div class="num">{{ listData.yanxuejilu}}次</div>
        <div class="name">研学记录数量</div>
        <div class="zhonglei zhonglei1">
          <p class="shuzi">{{ listData.nongqingjiaoyu }}</p>
          <p class="namenun">农情教育数量</p>
        </div>
        <div class="zhonglei zhonglei2">
          <p class="namenun">研学视频数量</p>
          <p class="shuzi">{{ listData.yanxueshiping }}</p>
        </div>
        <div class="zhonglei zhonglei3">
          <p class="shuzi">{{ listData.nongyekepu }}</p>
          <p class="namenun">农业科普数量</p>
        </div>
        <div class="zhonglei zhonglei4">
          <p class="namenun">外地咨询</p>
          <p class="shuzi">{{ listData.videoOut }}</p>
        </div>
        <div class="zhonglei zhonglei5">
          <p class="shuzi">{{ listData.video }}</p>
          <p class="namenun">视频学习</p>
        </div>
        <div class="zhonglei zhonglei6">
          <p class="namenun">专家咨询</p>
  
          <p class="shuzi">{{ listData.consult }}</p>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import moduleTitle from "./moduleTitle.vue";
  import { learnCount,getTypeNum } from "@api/njApi/honggan.js";
  export default {
    components: {
      moduleTitle,
    },
    data() {
      return {
        listData: "",
      };
    },
    created() {
      this.getList();
    },
    mounted() {},
    methods: {
      getList() {
        learnCount({})
          .then((res) => {
            this.listData = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
    
     <style  lang="scss" scoped>
  @function companyW($num) {
    @return ($num * 100)/1920;
  }
  @function companyH($num) {
    @return ($num * 100)/1080;
  }
  * {
    font-family: Source Han Sans CN;
    color: #f0f6f5;
    font-size: companyW(20vw);
    box-sizing: border-box;
  }
  .dry-power {
    position: relative;
    width: companyW(482vw);
    height: companyH(471vh);
    background: rgba(0, 20, 36, 0.2);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, #6d8a94, #6d8a94) 10 10;
    display: flex;
    align-items: center;
    
    .dry-power-content {
      margin-top: companyH(25vh);
      max-height: companyH(270vh);
      overflow: auto;
    }
  }
  .nongji {
    width: companyW(426vw);
    height: companyH(271vh);
    background-image: url(../../../assets/njfwimgs/ybgc.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    .num {
      font-size: companyW(24vw);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      width: companyW(85vw);
      text-align: center;
      top: companyH(110vh);
      left: companyW(170vw);
    }
    .name {
      font-size: companyW(16vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: companyH(155vh);
      left: companyW(167vw);
    }
    .zhonglei {
      display: flex;
      .shuzi {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }
      .namenun {
        font-size: companyW(16vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
      }
    }
    .zhonglei1 {
      position: absolute;
      top: companyH(45vh);
      left: companyW(43vw);
      .shuzi {
        margin-right: companyW(8vw);
      }
    }
    .zhonglei2 {
      position: absolute;
      top: companyH(45vh);
      right: companyW(43vw);
      .shuzi {
        margin-left: companyW(8vw);
      }
    }
    .zhonglei3 {
      position: absolute;
      top: companyH(130vh);
      left: companyW(13vw);
      .shuzi {
        margin-right: companyW(8vw);
      }
    }
    .zhonglei4 {
      position: absolute;
      top: companyH(130vh);
      right: companyW(13vw);
      .shuzi {
        margin-left: companyW(8vw);
      }
    }
  
    .zhonglei5 {
      position: absolute;
      top: companyH(208vh);
      left: companyW(36vw);
      .shuzi {
        margin-right: companyW(8vw);
      }
    }
    .zhonglei6 {
      position: absolute;
      top: companyH(208vh);
      right: companyW(36vw);
      .shuzi {
        margin-left: companyW(8vw);
      }
    }
  }
  </style>
    
    