<template>
    <div>
      <div class="shipin">
            <video
              :src="playingUrl"
              controls
              class="videoBox"
              autoplay
              loop
              muted
            ></video>
          </div>
      <div class="dry-powerss">
       
        <div class="module-titless"></div>
        <!-- <div class="xuanzess">
          <el-radio-group
            v-model="radio2"
            size="mini"
            fill="#384b5a"
            @input="handinput"
          >
            <el-radio-button label="0">视频</el-radio-button>
            <el-radio-button label="1">省内资讯</el-radio-button>
            <el-radio-button label="2">国内资讯</el-radio-button>
          </el-radio-group>
        </div> -->
        <div class="dry-power-contentss" v-if="radio2 == 0">
          <video
            v-for="(item, index) in listData"
            :key="item.videoUrl"
            :src="item.videoUrl"
            :id="'video' + index"
            controls
            class="videoBoxss"
            loop
            muted
            @play="pauseOtherVideos(item,index)"
          ></video>
        </div>
        <div class="dry-power-contentss" v-if="radio2 == 1 || radio2 == 2">
          <div
            class="maing"
            v-for="item in conData"
            :key="item.id"
            @click="opendialog(item.id)"
          >
            <div class="leftBox">
              <span>{{ item.title }} </span>
              <span>{{ item.source }} {{ item.time }}</span>
            </div>
            <div class="aa">
              <img :src="item.img" class="img" />
            </div>
          </div>
        </div>
        <tanchuang
          :seeVisible="seeVisible"
          :id="id"
          @closemask="closemask"
        ></tanchuang>
      </div>
    </div>
  </template>
  
  <script>

  import { getVideos, consult } from "@api/njfw/njfw.js";
//   import tanchuang from "./tanchuang3.vue";
  export default {
    components: {
   
    //   tanchuang,
    },
    created() {
      this.getList();
    },
    data() {
      return {
        listData: [],
        radio2: "0",
        conData: [],
        seeVisible: false,
        id: "",
        playingUrl:''
      };
    },
    mounted() {},
    methods: {
      getList() {
        getVideos({
          videoType:2
        })
          .then((res) => {
            this.listData = res.data.records;
            this.playingUrl=this.listData[0].videoUrl
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handinput(val) {
        if (val == 0) {
          this.getList();
        } else {
          consult({
            type: this.radio2 == 1 ? "省内资讯" : "国内资讯",
          })
            .then((res) => {
              this.conData = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      opendialog(id) {
        this.id = id;
        this.seeVisible = true;
      },
      closemask(val) {
        this.seeVisible = val;
      },
      pauseOtherVideos(item,index){
     this.playingUrl=item.videoUrl
        let video = document.getElementById("video" + index);
        video.pause();
      }
  
  
      // 播放器一个播放其他暂停
      // pauseOtherVideos(index) {
      //   let video = document.getElementById("video" + index);
      //   video.play();
      //   for (var i = 0; i <= this.listData.length; i++) {
      //     if (i != index) {
      //       let video2 = document.getElementById("video" + i);
      //       video2.pause();
      //     }
      //   }
      // },
    },
  };
  </script>
  
   <style  lang="scss" >
  @function companyW($num) {
    @return ($num * 100)/1920;
  }
  @function companyH($num) {
    @return ($num * 100)/1080;
  }
  @font-face {
    font-family: Source Han Sans CN;
    src: url(../../../assets/font/np4SotSdlbui.woff);
  }
  * {
    font-family: Source Han Sans CN;
    color: #f0f6f5;
    font-size: companyW(20vw);
    box-sizing: border-box;
  }
  .dry-powerss {
    position: relative;
    width: companyW(878vw);
    height: companyH(445vh);
    background: rgba(0, 20, 36, 0.4);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, #6d8a94, #6d8a94) 10 10;
   
    .dry-power-contentss {
      margin-top: companyH(60vh);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      max-height: companyH(357vh);
      overflow: auto;
      padding: companyH(20vh);
      padding-right: 0 !important;
    }
    .videoBoxss {
      width: companyW(262vw);
      height: companyH(139vh);
      margin-bottom: companyH(20vh);
      margin-right: companyW(18vw);
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    width: 5px;
  }
  .module-titless {
    position: absolute;
    top: companyH(-37vh);
    left: companyW(-33vw);
    width: companyW(910vw);
    height: companyH(109vh);
    background-image: url(../../../assets/njfwimgs/yxhd.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
 
  }
  .xuanzess {
    position: absolute;
    top: companyH(-2vh);
    right: 0;
  }
  .el-radio-button__inner {
    background-color: #1d3040;
    border: 1px solid #607583;
    border-radius: 0 !important;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inne {
    background-color: #fff;
  }
  .maing {
    width: companyW(400vw);
    height: companyH(169vh);
    background: #001424;
    border: 2px solid #a3c6ce;
    border-radius: companyH(10vh);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: companyW(10vw);
    margin-bottom: companyW(18vw);
    margin-right: companyW(20vw);
    .leftBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: companyH(116vh);
      margin-left: companyW(10vw);
      span:nth-child(1) {
        font-size: companyH(20vh);
        font-weight: bold;
        color: #ffffff;
      }
      span:nth-child(2) {
        font-size: companyH(18vh);
        font-weight: 500;
        color: #ffffff;
      }
    }
  
    .img,
    .aa {
      width: companyW(116vw);
      height: companyH(116vh);
    }
    .imgBox {
      width: companyW(20vw);
      height: companyH(20vh);
    }
  }
  .videoBox {
      width: companyW(878vw);
      height: companyH(494vh);
      margin:  companyH(20vh) 0;
    }
  </style>
  
  