<template>
  <div class="learin">
    <!-- 标题 -->
    <Title
      class="learinTitle"
      njTitle="title"
      typeOf="distributionImg"
      :title="address + '农事研学'"
    ></Title>
    <div class="rightBgc anniu1" @click="jumpshouye">
      <img src="../../assets/njfwimgs/shouye.png" class="imgBox" />
      <p>首页</p>
    </div>
    <a class="rightBgc anniu2" href="http://nsfwgl.ifasun.com" target="_blank">
      <img src="../../assets/njfwimgs/houtai.png" class="imgBox" />
      <p>后台</p>
    </a>
    <div class="rightBgc anniu3" @click="toback">
      <img src="../../assets/njfwimgs/quanping.png" class="imgBox" />
      <p>全屏</p>
    </div>
    <div class="main">
      <div class="left">
        <!-- <div class="left-top">
          <moduleTitle title="农业科普"></moduleTitle>
          <vueSeamlessScroll
            :data="leftTopData"
            class="seamless-warp"
            :class-option="classOption"
          >
            <div v-for="(item, index) in leftTopData" :key="item.id">
              <div class="individual">
                <div class="indexIcon">{{ index }}</div>
                <p>{{ item.title }}</p>
              </div>
              <img src="../../assets/distributionImg/fenge.png" />
            </div>
          </vueSeamlessScroll>
        </div> -->
        <Tubiao class="tubiao"></Tubiao>

        <div class="left-bottom">
          <moduleTitle title="研学客户分析"></moduleTitle>
          <!-- <div class="news">
            <div class="education" v-for="item in leftBottom" :key="item.id">
              <img :src="item.img" />
              <div class="introduce">
                <p>{{ item.title }}</p>
                <p>{{ item.time }}</p>
              </div>
            </div>
          </div> -->
          <div id="myChart"></div>
        </div>
      </div>
      <!-- 中间视频和视频列表 -->
      <div class="middle">
        <Splb></Splb>
      </div>
  
      <div class="right">
        <div class="right-top">
          <moduleTitle title="活动记录"></moduleTitle>
          <div class="bigrecord">
            <div class="bgcBox" v-for="(item,index) in researchData" :key="index">
              <div style="display: flex">
                <img :src=item.researchPhoto alt="" />
                <div class="letter">
                  <p>{{item.researchTitle}}</p>
                  <p>研学时间：{{item.activityStartTime}} &nbsp; &nbsp; &nbsp;名额：{{item.maxPeople}}人</p>
                  <p>报名时间：{{item.entryStartTime}}</p>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <el-progress
                  type="circle"
                  :percentage= Math.round(item.reservationPeople/item.maxPeople*100)  
                  width="50"
                ></el-progress>

                <p class="bilie">{{item.reservationPeople}}/{{item.maxPeople}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="right-bottom">
          <!-- <Tubiao></Tubiao> -->
          <moduleTitle title="研学记录"></moduleTitle>
          <div class="productType">
            <button :class="check == 0 ? 'check' : ''" @click="choice(0)">
              已核销
            </button>
            <button
              :class="check == 1 ? 'check' : ''"
              @click="choice(1)"
            >
              已预约
            </button>
            <button
              :class="check ==2 ? 'check' : ''"
              @click="choice(2)"
            >
              已完成
            </button>
          </div>

          <div class="titleHead">
            <p>研学对象</p>
            <p>核销人数/预约人数</p>
            <p>核销时间</p>
          </div>
          <div class="specific" v-for="(item,index ) in orderDta" :key="index">
            <p>{{item.name}}</p>
            <p>10/40</p>
            <p>{{ item.appointmentTime }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/title.vue";
import moduleTitle from "./components/moduleTitle.vue";
import Splb from "./components/splb.vue";
import Tubiao from "./components/tubiao.vue";
import {
  consult,
  research,
  ResearchOrder,
  OrdergetTypeNum,
} from "../../api/njApi/honggan";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "AgricultureLearin",
  components: {
    Title,
    moduleTitle,
    Splb,
    Tubiao,
    vueSeamlessScroll,
  },
  data() {
    return {
      address: "",
      leftTopData: [],
      leftBottom: [],
      rightTop: [],
      learnDatra: "",
      researchData: "",
      check: 0,
      orderDta: "",
    };
  },
  created() {
    this.address = localStorage.getItem("address");
    this.getData();
    this.getorderdata();
    this.getEachData();
  },
  mounted() {},
  //监听属性 类似于data概念
  computed: {
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    getorderdata() {
      ResearchOrder({
        pageNum: 1,
        pageSize: 10,
        status: this.check,
      }).then((res) => {
        this.orderDta = res.data.records;
        this.orderDta.forEach((item) => {
          item.appointmentTime = item.appointmentTime.substring(5).slice(0, -3);
        });
      });
    },
    getEachData() {
      OrdergetTypeNum().then((res) => {
        const colorList = [
          "#17B1F7",
          "#B98CED",
          "#25FFFF",
          "#85F8C7",
          "#E1D978",
        ];
        const dataset = res.data;
        var sum = 0;
        dataset.forEach((item) => {
          sum += item.num;
          if (item.appointmentType == 0) {
            item.name = "学习研学";
          } else if (item.appointmentType == 1) {
            item.name = "企业研学";
          } else {
            item.name = "个人研学";
          }
          item.value = item.num;
          item.proportion = (item.value / sum) * 100;
        });
        const radius = ["10%", "20%", "28%"];
        const center = ["35%", "50%"];
        const unit = "个";
        const imgCenter = {
          top: "35%",
          left: "10%",
          width: 460,
          height: 338,
        };
        const seriesData = dataset.map((item, index) => {
          return {
            value: item.value,
            name: item.name,
            itemStyle: {
              borderWidth: 6,
              borderColor: "transparent",
            },
          };
        });

        const total = dataset.reduce((prev, cur) => {
          return prev + cur.value;
        }, 0);

        function _pie2() {
          let dataArr = [];
          for (var i = 0; i < 100; i++) {
            if (i % 2 === 0) {
              dataArr.push({
                name: (i + 1).toString(),
                value: 25,
                itemStyle: {
                  normal: {
                    color: "#01C2F7",
                    borderWidth: 0,
                    borderColor: "rgba(0,0,0,0)",
                  },
                },
              });
            } else {
              dataArr.push({
                name: (i + 1).toString(),
                value: 20,
                itemStyle: {
                  normal: {
                    color: "rgba(0,0,0,0)",
                    borderWidth: 0,
                    borderColor: "rgba(0,0,0,0)",
                  },
                },
              });
            }
          }
          return dataArr;
        }
        const myChart = this.$echarts.init(document.getElementById("myChart"));
        myChart.setOption(
          {
            // backgroundColor: "#001029",
            color: colorList,
            legend: {
              show: true,
              orient: "vertical",
              right: "16%",
              top: "middle",
              data: dataset,
              // formatter: function (name) {
              //   let objItem = dataset.find((item) => item.name === name);
              //   if (objItem?.proportion) {
              //     return `{label| ${name}:} {dataValue|${objItem.proportion.toFixed(
              //       2
              //     )}}{valueUilt1|%}`;
              //   }
              // }, 
              itemGap: 15,
              textStyle: {
                color: "#fff",
                rich: {
                  label: {
                    width: 80,
                    overflow: "truncate",
                    ellipsis: "...",
                    fontSize: 14,
                    fontFamily: "Microsoft YaHei",
                    fontWeight: 400,
                    color: "rgba(160, 206, 224, 1)",
                  },
                  dataValue: {
                    textShadowColor: "rgba(0, 234, 255, 0.70)",
                    textShadowBlur: 10,
                    fontSize: 12,
                    fontFamily: "D-DIN",
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    padding: 0,
                    verticalAlign: "bottom",
                  },
                  valueUilt: {
                    fontSize: 14,
                    fontFamily: "SourceHanSansCN-Regular",
                    // fontWeight: "bold",
                    color: "#FFFFFF",
                    verticalAlign: "bottom",
                    // padding: [5, 0, 5, 25],
                    textShadowColor: "rgba(0, 234, 255, 0.70)",
                    textShadowBlur: 10,
                  },
                  valueUilt1: {
                    fontSize: 12,
                    fontFamily: "SourceHanSansCN-Regular",
                    // fontWeight: "bold",
                    color: "#FFFFFF",
                    // padding: [5, 0, 5, 25],
                    verticalAlign: "bottom",
                    textShadowColor: "rgba(0, 234, 255, 0.70)",
                    textShadowBlur: 10,
                  },
                  dataPercent: {
                    fontSize: 18,
                    fontFamily: "D-DIN Condensed",
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    verticalAlign: "bottom",
                    textShadowColor: "rgba(0, 234, 255, 0.70)",
                    textShadowBlur: 10,
                    // padding: [5, 0, 5, 25],
                  },
                },
              },
            },
            tooltip: {
              trigger: "item",
            },
            graphic: {
              //图形中间图片
              elements: [
                {
                  type: "image",
                  style: {
                    image: "", //在这里放背景
                    width: imgCenter?.width || 400,
                    height: imgCenter?.height || 300,
                  },
                  left: imgCenter?.left || 0,
                  top: imgCenter?.top || 80,
                },
              ],
            },
            series: [
              {
                name: "",
                type: "pie",
                // radius: radius?.[2]?.endsWith("%")
                //   ? [radius?.[2], Number(radius?.[2].slice(0, -1)) + 10 + "%"]
                //   : [radius?.[2], Number(radius?.[2]) + 14] || ["65%", "75%"], //隐藏
                center: ["30%", "50%"],
                radius: ["40%", "50%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                data: seriesData,
                tooltip: {
                  formatter: function (params) {
                    return (
                      params.marker +
                      params.name +
                      " : " +
                      params.value +
                      unit +
                      "<br/>" +
                      params.marker +
                      "占比：" +
                      params.percent +
                      "%"
                    );
                  },
                },
              },
              {
                name: "虚线",
                type: "pie",
                zlevel: 3,
                silent: true,
                // radius: radius?.[1]?.endsWith("%")
                //   ? [radius?.[1], Number(radius?.[1]?.slice(0, -1)) + 1 + "%"]
                //   : [radius?.[1], Number(radius?.[1]) + 1] || ["51%", "52%"],//隐藏
                // center: center || ["200", "50%"],
                center: ["30%", "50%"],

                radius: ["30%", "25%"],

                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                data: _pie2(),
              },
              {
                //内圆
                name: "内圆",
                type: "pie",
                radius:  "28%",
                center: ['30%', '50%'],
                // center: center || ["200", "50%"],
                z: 1,
                itemStyle: {
                  normal: {
                    color: "#0A2D4F",

                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "center", // 展示在中间位置
                    formatter: () => {
                      return `{a|${total}}{b| ${unit || ""}}`;
                    },
                    rich: {
                      a: {
                        color: "#fff",
                        fontSize: 24,
                        fontWeight: 900,
                      },
                      b: {
                        color: "#fff",
                        fontSize: 14,
                        lineHeight: 100,
                        height: 40,
                        padding: [5, 0, 0, 0],
                        // lineHeight: '20px'
                      },
                    },
                  },
                },
                hoverAnimation: false,
                tooltip: {
                  show: false,
                },
                data: [100],
              },
            ],
          },
          true
        );
      });
    },
    getData() {
      consult({
        type: "农业科普",
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.code == 1) {
          this.leftTopData = res.data.records;
        }
      });

      consult({
        type: "农情教育",
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.code == 1) {
          this.leftBottom = res.data.records;
        }
      });

      consult({
        type: "研学记录",
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.code == 1) {
          this.rightTop = res.data.records;
        }
      });
      research({
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        this.researchData = res.data.records;
        this.researchData.forEach((element) => {
          element.activityStartTime = element.activityStartTime.slice(0, 10);
        });
      });
    },
    choice(value) {
      this.check = value;
      this.getorderdata();
    },
    jumpshouye() {
      this.$router.push({
        path: "/",
      });
    },

    toback() {
      document.documentElement.requestFullscreen();
    },
  },
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}

.learin {
  background-color: #1c2f3e;
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  overflow: hidden;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  .learinTitle {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
  }
  .main {
    width: companyW(1920vw);
    display: flex;
    justify-content: space-around;
    height: companyH(1010vh);
    align-items: space-around;
  }

  .left-top,
  .left-bottom,
  .right-top,
  .right-bottom {
    position: relative;
    width: companyW(482vw);
    height: companyH(471vh);
    background-color: #142737;
    padding-top: companyH(45vh);
  }
  .left-top {
    margin: companyH(20vh) 0;
    .individual {
      display: flex;
      align-content: center;
      margin-top: companyH(20vh);
      .indexIcon {
        width: companyW(23vw);
        height: companyH(23vh);
        background-color: #2ea8f6;
        border-radius: companyW(3vw);
        text-align: center;
        line-height: companyH(23vh);
        margin: 0 companyW(10vw);
      }
      p {
        /* 防止文本换行 */
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        width: companyW(420vw);
      }
    }
  }
  .right-top {
    margin: companyH(20vh) 0;
    .record {
      padding: companyW(8vw) companyW(5vw) companyW(10vw) companyW(25vw);

      p:nth-child(1) {
        color: #849eb2;
        font-size: companyW(20vw);
        margin-bottom: companyH(20vh);
      }
    }
  }
  .tubiao {
    margin: companyH(20vh) 0;
  }
  .left-bottom {
    .education {
      width: companyW(442vw);
      height: companyH(150vh);
      background-color: #0e2131;
      margin: companyH(20vh) auto;
      border-radius: companyW(20vw);
      display: flex;
      align-items: center;
      padding: 0 companyW(20vw);
      img {
        width: companyW(110vw);
        height: companyH(110vh);
        margin-right: companyW(30vw);
      }
      .introduce {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: companyH(110vh);
        p:nth-child(2) {
          align-self: flex-end;
          color: #93989c;
          font-size: companyW(18vw);
        }
      }
    }
  }
}

.rightBgc {
  width: companyW(90vw);
  height: companyH(30vh);
  background-color: #4a515a;
  border-radius: companyW(5vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 companyW(10vw) 0 companyW(10vw);
  .imgBox {
    width: companyW(24vw);
    height: companyH(24vh);
  }
  p {
    font-size: companyH(18vh);
  }
}
.anniu1 {
  position: absolute;
  left: companyW(30vw);
  top: companyH(8vh);
}
.anniu2 {
  position: absolute;
  left: companyW(134vw);
  top: companyH(8vh);
}
.anniu3 {
  position: absolute;
  right: companyW(30vw);
  top: companyH(8vh);
}
.seamless-warp {
  height: companyH(400vh);
  overflow: hidden;
}
/* 为元素添加纵向滚动条 */
.news {
  max-height: companyH(420vh); /* 设置元素的最大高度 */
  overflow-y: auto; /* 当内容超出最大高度时，显示纵向滚动条 */
}
.bigrecord {
  max-height: companyH(420vh); /* 设置元素的最大高度 */
  overflow-y: auto; /* 当内容超出最大高度时，显示纵向滚动条 */
  overflow-x: hidden;
}
/* 设置滚动条的宽度 */
::-webkit-scrollbar {
  width: 5px;
}

/* 设置滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* 设置滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

/* 鼠标悬停在滚动条滑块上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
.bgcBox {
  width: companyW(441vw);
  height: companyH(121vh);
  background: #09161e;
  border-radius: 5px;
  border: 1px solid #364a57;
  margin: companyH(20vh) auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: companyW(80vw);
    height: companyH(80vh);
    border-radius: 5px;
  }
}
.letter {
  display: flex;
  flex-direction: column;
  margin-left: companyW(13vw);
  p {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: companyW(14vw);
    color: #848a8f;
  }

  p:nth-child(1) {
    font-size: companyW(16vw);
    color: #ffffff;
  }
  p:nth-child(2) {
    margin: companyH(10vh) 0;
  }
}
.bilie {
  font-family: DIN;
  font-weight: 500;
  font-size: companyW(16vw);
  color: #c1d3df;
}

.productType {
  width: companyW(400vw);
  height: companyH(30vh);
  margin-left: companyW(200vw);
  margin-top: companyH(20vh);

  button {
    background: #1b3244;
    color: #a0a0a2;
    width: companyW(80vw);
  }
  .check {
    background: #1e7ab7 !important;
    color: #fff !important;
  }
}
.titleHead {
  width: companyW(482vw);
  height: companyH(59vh);
  background: #182c3e;
  border-bottom: 1px solid #41567c;
  margin-top: companyH(20vh);
  display: flex;
  align-items: center;
  padding: 0 companyW(24vw);
  p {
    font-family: Source Han Sans SC;
    font-weight: 500;
    font-size: companyW(16vw);
    color: #a8c3e6;
  }
  p:nth-child(2) {
    margin: 0 companyW(42vw) 0 companyW(123vw);
  }
}
.specific {
  display: flex;
  padding: 0 companyW(24vw);
  height: companyH(48vh);
  align-items: center;
  border-bottom: 1px solid #1c2a40;
  p {
    font-family: Source Han Sans SC;
    font-weight: 400;
    font-size: companyW(16vw);
    color: #ffffff;
  }
  p:nth-child(1) {
    width: companyW(157vw);
    /* 防止文本换行 */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p:nth-child(2) {
    margin: 0 companyW(80vw) 0 companyW(60vw);
  }
}
#myChart {
  width: companyW(500vw);
  height: companyH(500vh);
}
</style>
